/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Flex,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import InformationComponent from './components/InformationComponent/InformationComponent';
import ProjectUpdatesComponent from './components/ProjectUpdatesComponent/ProjectUpdatesComponent';
import TimelineComponent from './components/TimelineComponent/TimelineComponent';
import FAQsComponent from './components/FAQsComponent/FAQsComponent';
import FinancialDetailsComponent from './components/FinancialDetailsComponent/FinancialDetailsComponent';
import GalleryComponent from './components/GalleryComponent/GalleryComponent';
import { useEffect, useState } from 'react';
import {
	addProperty,
	getProjectInfo,
	updateProperty,
} from '../../services/propertyService';
import { showToast } from '../../utils/Toasts';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../routes/Routes';
import { validateProjectData } from './validations/projectDataValidations';
import { formatProjectData } from '../../utils/projectDataUtils';
import LoadingComponent from '../../components/LoadingComponent';
import { AWS_S3 } from '../../constants/constants';
import TabsHeaderComponent from './components/TabsHeaderComponent';
import { initialPropertyInfo, tabs, tabTitles } from './data';
import { useTranslation } from 'react-i18next';
import useProject from '../../hooks/useProject';
import { validateIsValidImage } from '../../utils/validations/generalDataValidations';
import ModalComponent from '../../components/ModalComponent';
import SuccessfulComponent from '../../components/SuccessfulComponent';
import { propertyStatus } from '../../data/optionsData';

const AddProperty = () => {
	const { t } = useTranslation();
	const [isLoading, setLoading] = useState();
	const navigate = useNavigate();
	const toast = useToast();
	const [incorrectData, setIncorrectData] = useState([]);
	const [propertyInfo, setPropertyInfo] = useState(initialPropertyInfo);
	const [publishLoading, setPublishLoading] = useState(false);
	const [draftLoading, setDraftLoading] = useState(false);
	const [initialFeatureMedia, setInitialFeatureMedia] = useState(undefined);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [txHash, setTxHash] = useState();
	const [informationTabData, setInformationTabData] = useState(undefined);
	const [galleryTabData, setGalleryTabData] = useState();
	const [financialTabData, setFinancialTabData] = useState();
	const [updatesTabData, setUpdatesTabData] = useState();
	const [faqTabData, setFAQTabData] = useState();
	const [timelineTabData, setTimelineTabData] = useState();
	const [tabChangeDisabled, setTabChangeDisabled] = useState();
	const [currentTab, setCurrentTab] = useState(tabs[0]);
	const { createProjectAndUpdateStatus, updateProject } = useProject();

	const location = useLocation();
	const { state } = location;

	const handleCloseModal = () => {
		onClose();
		navigate(`/${PrivateRoutes.Admin}`);
	};

	useEffect(() => {
		if (state?.projectId) {
			setLoading(true);
			const getProjectDetails = async () => {
				const { data } = await getProjectInfo(state.projectId);
				const initialPropertyInfo = formatProjectData(data);

				setPropertyInfo(initialPropertyInfo);
				setInitialFeatureMedia(() => {
					const media = initialPropertyInfo?.gallery.filter(item =>
						item?.fileName?.endsWith(
							initialPropertyInfo.data.featureImage,
						),
					)[0];
					return {
						url: AWS_S3 + '/' + media?.fileName,
						isImage: validateIsValidImage(media?.fileName),
						fileName: media?.fileName,
					};
				});
				setLoading(false);
			};
			getProjectDetails();
		}
	}, []);

	const deleteFromIconrrectData = name => {
		incorrectData.indexOf(name) &&
			delete incorrectData[incorrectData.indexOf(name)];
	};

	const showErrorToast = () =>
		showToast(
			toast,
			'There was an error',
			'Please verify that you have saved the information correctly',
		);

	const validateErrors = (isDraft, data) => {
		const galleryNum =
			(propertyInfo.gallery?.length ?? 0) +
			(data?.gallery?.length ?? 0) -
			(data?.deletedImages?.length ?? 0);
		const inputError = validateProjectData(isDraft, data, galleryNum);

		setIncorrectData(inputError);

		return inputError;
	};

	const handleSave = async isDraft => {
		isDraft ? setDraftLoading(true) : setPublishLoading(true);
		const data = {
			...informationTabData,
			propertyStatus: propertyStatus.Upcoming,
			...galleryTabData,
			featureImage: galleryTabData?.featureImage?.fileName,
			...financialTabData,
			...updatesTabData,
			...faqTabData,
			...timelineTabData,
			isDraft,
		};

		const errors = isDraft ? [] : validateErrors(isDraft, data);
		if (errors.length === 0) {
			const result = await addProperty(data);
			if (result.success) {
				showToast(toast, 'Success', 'Property added successfully');
				if (!isDraft) {
					try {
						const project = await createProjectAndUpdateStatus(
							result.data.projectData,
							result.data.projectId,
						);
						setTxHash(project.receipt?.hash);
						onOpen();
					} catch (error) {
						showToast(toast, 'There was an error', error.reason);
					}
				} else {
					navigate(`/${PrivateRoutes.Admin}`);
				}
			} else {
				showToast(toast, 'There was an error', result.message);
			}
		} else {
			showToast(
				toast,
				'There was an error',
				'Please, the fields must be filled in correctly.',
			);
		}
		isDraft ? setDraftLoading(false) : setPublishLoading(false);
	};

	const handleNavigateButtons = event => {
		const value = event.target.value;
		const index = currentTab.index;
		if (value === 'next') {
			setCurrentTab(tabs[index + 1]);
		} else {
			setCurrentTab(tabs[index - 1]);
		}
	};

	const handleUpdate = async isDraft => {
		setPublishLoading(true);

		const data = {
			...informationTabData,
			...galleryTabData,
			featureImage: galleryTabData?.featureImage?.featureImageName,
			...financialTabData,
			...updatesTabData,
			...faqTabData,
			...timelineTabData,
			isDraft,
		};

		const errors = validateErrors(isDraft, data);

		if (errors.length === 0) {
			const result = await updateProperty(data, state.projectId);
			if (result.success) {
				if (result.data) {
					try {
						await updateProject(
							result.data.projectData,
							result.data.projectId,
						);
					} catch (error) {
						showToast(toast, 'There was an error', error.reason);
					}
				}
				showToast(toast, 'Success', 'Property updated successfully');
				navigate(`/${PrivateRoutes.Admin}`);
			} else {
				showToast(toast, 'There was an error', result.message);
			}
		} else {
			showToast(
				toast,
				'There was an error',
				'Please, the fields must be filled in correctly.',
			);
		}
		setPublishLoading(false);
	};

	return (
		<>
			{isLoading ? (
				<LoadingComponent />
			) : (
				<>
					<Stack w='100%' gap='30px' h='100%'>
						<Text
							fontSize='32px'
							fontWeight='700'
							lineHeight='100%'
						>
							{t('addProperty.adminPanel.title')}
						</Text>
						<TabsHeaderComponent
							state={state}
							handleUpdate={
								tabChangeDisabled
									? showErrorToast
									: handleUpdate
							}
							currentTab={currentTab}
							draftLoading={draftLoading}
							publishLoading={publishLoading}
							tabChangeDisabled={tabChangeDisabled}
							handleSave={
								tabChangeDisabled ? showErrorToast : handleSave
							}
							onSelectTab={setCurrentTab}
							tabs={tabs}
						/>
						{currentTab.title === tabTitles.Information ? (
							<InformationComponent
								initialValues={propertyInfo}
								incorrectData={incorrectData}
								deleteFromIconrrectData={
									deleteFromIconrrectData
								}
								handleOnChange={setInformationTabData}
								currentValues={informationTabData}
							/>
						) : currentTab.title === tabTitles.Gallery ? (
							<GalleryComponent
								propertyInfo={propertyInfo}
								incorrectData={incorrectData}
								initialFeatureMedia={initialFeatureMedia}
								handleOnChange={setGalleryTabData}
								currentValues={galleryTabData}
							/>
						) : currentTab.title === tabTitles.FinancialDetails ? (
							<FinancialDetailsComponent
								initialValues={propertyInfo}
								incorrectData={incorrectData}
								deleteFromIconrrectData={
									deleteFromIconrrectData
								}
								handleOnChange={setFinancialTabData}
								currentValues={financialTabData}
								informationTabData={informationTabData}
								isPurchased={
									informationTabData?.purchasedOrPledge ===
										'Purchased' ||
									propertyInfo?.purchasedOrPledge ===
										'Purchased'
								}
								setPropertyInfo={setPropertyInfo}
							/>
						) : currentTab.title === tabTitles.ProjectUpdates ? (
							<ProjectUpdatesComponent
								initialValues={propertyInfo}
								handleOnChange={setUpdatesTabData}
								currentValues={updatesTabData}
								setTabChangeDisabled={setTabChangeDisabled}
								setPropertyInfo={setPropertyInfo}
							/>
						) : currentTab.title === tabTitles.FAQs ? (
							<FAQsComponent
								initialValues={propertyInfo}
								handleOnChange={setFAQTabData}
								currentValues={faqTabData}
								setTabChangeDisabled={setTabChangeDisabled}
								setPropertyInfo={setPropertyInfo}
							/>
						) : currentTab.title === tabTitles.Timeline ? (
							<TimelineComponent
								initialValues={propertyInfo}
								handleOnChange={setTimelineTabData}
								currentValues={timelineTabData}
								setTabChangeDisabled={setTabChangeDisabled}
								setPropertyInfo={setPropertyInfo}
							/>
						) : null}
						<Flex
							gap='10px'
							mx='auto'
							h='100%'
							alignItems='flex-end'
						>
							{currentTab.index > 0 && (
								<Button
									variant='secondary'
									value='prev'
									onClick={handleNavigateButtons}
								>
									Previous
								</Button>
							)}
							{currentTab.index < 5 && (
								<Button
									variant='main'
									value='next'
									onClick={handleNavigateButtons}
								>
									Next
								</Button>
							)}
						</Flex>
						<ModalComponent
							isOpen={isOpen}
							onClose={onClose}
							onOpen={onOpen}
							modalCross={false}
							closeOnOverlayClick={false}
						>
							<SuccessfulComponent
								w='500px'
								p='20px'
								modal={false}
								buttonTitle={'Go back'}
								handleClick={handleCloseModal}
								text={t(
									'projectDetails.offerDetails.succesful.transactionSuccess',
								)}
								txHash={txHash}
							/>
						</ModalComponent>
					</Stack>
				</>
			)}
		</>
	);
};

export default AddProperty;
